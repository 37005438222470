import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Firing Lots of Growth Bullets`}</h1>
    <p>{`We’re focused on ecommerce growth today, helping my wife grow her store.`}</p>
    <p><strong parentName="p">{`Sharing 3 learnings today:`}</strong></p>
    <p>{`Learning #1 - `}<strong parentName="p">{`results from Ben’s email capture experiment`}</strong></p>
    <p><strong parentName="p">{`Emails are critical in ecom. The overarching strategy of ecommerce is:`}</strong></p>
    <ul>
      <li parentName="ul">{`Spend `}{`$`}{`$`}{` on ads to get traffic`}</li>
      <li parentName="ul">{`Ask traffic for their email addresses/phone numbers (targeting 10%+ of
visitors) so you have permission to contact them later`}</li>
      <li parentName="ul">{`Setup automated emails to customers based on their actions (eg. abandoned
cart, first time discount etc..)`}</li>
    </ul>
    <p>{`The store’s email capture rate was 5%, we wanted to double it. Ben designed a 1
day test of our “welcome mat” (aka the popup you see when you first visit the
site)`}</p>
    <p>{`It started as a pretty crappy out-of-the-box popup that Klayivo (best tool for
email marketing for ecom) gives you.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "524px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHs7KheKH//xAAaEAACAwEBAAAAAAAAAAAAAAABAgADERIT/9oACAEBAAEFAs0cwRqkZvGqVgBf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECMREhMqH/2gAIAQEABj8Csv0ZmUU2cI0f/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERUSFBkTH/2gAIAQEAAT8hTIcukrdGMJbzsfLIY3e8yFpC0qP/2gAMAwEAAgADAAAAEAPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHhABAAICAQUAAAAAAAAAAAAAAQARITFBUXGRwfD/2gAIAQEAAT8QONrlt9e8+U9yzoBha+CaoOpyhqKZcGCDgNAAo8T/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ecom Popup",
          "title": "Ecom Popup",
          "src": "/static/aa426ee75cf77c2f91d2322de7ff4be1/50ba8/ecom-popup.jpg",
          "srcSet": ["/static/aa426ee75cf77c2f91d2322de7ff4be1/a6407/ecom-popup.jpg 400w", "/static/aa426ee75cf77c2f91d2322de7ff4be1/50ba8/ecom-popup.jpg 524w"],
          "sizes": "(max-width: 524px) 100vw, 524px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Not super juicy.`}</p>
    <p>{`Ben made 3 simple tweaks:`}</p>
    <ol>
      <li parentName="ol">{`Tweak the copy`}</li>
      <li parentName="ol">{`Add a picture`}</li>
      <li parentName="ol">{`Make it pop up faster (1 sec after landing. Before we had it at 7seconds
because we thought 1 sec might be too abrupt).`}</li>
    </ol>
    <p>{`1 day later, the rate 2x’d from 5% to 10%... let’s see if it stays that way as
the sample size gets bigger.`}</p>
    <p><strong parentName="p">{`Learning #2 - Get more customer data`}</strong></p>
    <p>{`A lotta people use the buzzword “DTC” nowadays. Direct-to-Consumer.`}</p>
    <p>{`The idea is that hey, instead of selling through target, walmart, etc.. we
cutout the store and sell direct to customers!`}</p>
    <p>{`In reality, most ecom stores just traded one middleman for another.`}</p>
    <p>{`Instead of giving target 30% of revenue to reach customers, ecom brands give
30%+ to facebook & google to advertise instead.`}</p>
    <p>{`So a lot of the DTC stuff is overhyped...except one key thing. The brand
actually knows who it’s customer is. When I talked to Moiz Ali from Native, he
said it best:`}</p>
    <p>{`“Big deodorant brands like Old Spice might sell a billion dollars of deodorant,
but they don’t know who their customers are. Their customer is actually Target.
That’s who buys from them.`}</p>
    <p>{`For us, we know that it’s Jennifer in Florida who buys. We know her favorite
scent. We have her email address and phone number. And she knows she can email
or call us and talk to us whenever she wants”`}</p>
    <p>{`This is spot on. One of the reasons big traditional companies are buying DTC
companies is because they have a rich dataset of customers. Plus those brands
can offer a better experience.`}</p>
    <p>{`So Ben built that into the store today. He launched an experiment to ask the
customer to share data like name, birthday etc.. at the end of every checkout,
to start building out that data set.`}</p>
    <p><strong parentName="p">{`Here’s what our experiment doc looks like for this one:`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1142px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd1KiSg//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEQAv/aAAgBAQABBQJGor//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAADAQEAAAAAAAAAAAAAAAAAARExEP/aAAgBAQABPyGIYUFYZ7//2gAMAwEAAgADAAAAECMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBAAAgICAwAAAAAAAAAAAAAAAAERQRAhMVFh/9oACAEBAAE/EKjUeGliTuYLQ+T/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Growth Experiments Doc",
          "title": "Growth Experiments Doc",
          "src": "/static/ea6ffa6e2d2d934c1a628e3d08cb0e9e/be26e/growth-experiment-doc.jpg",
          "srcSet": ["/static/ea6ffa6e2d2d934c1a628e3d08cb0e9e/a6407/growth-experiment-doc.jpg 400w", "/static/ea6ffa6e2d2d934c1a628e3d08cb0e9e/a24e6/growth-experiment-doc.jpg 800w", "/static/ea6ffa6e2d2d934c1a628e3d08cb0e9e/be26e/growth-experiment-doc.jpg 1142w"],
          "sizes": "(max-width: 1142px) 100vw, 1142px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`If you wanna make a copy of our template for this,
`}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/75ulv2g0gwa8hq8390fz/reh8hohq9e080zs2/aHR0cHM6Ly9kb2NzLmdvb2dsZS5jb20vc3ByZWFkc2hlZXRzL2QvMVNUbWd5ZUE5V3pEY2EwM0w0b2IwN0JnYTV3OEduU3ZxeUxpRDBsUEZhaEEvZWRpdD91c3A9c2hhcmluZw=="
      }}><strong parentName="a">{`click here`}</strong></a></p>
    <p>{`Executing this is all about doing it in a way that doesn’t get in the way of the
customer purchasing anything.`}</p>
    <p>{`So, we’re going to start by putting it just on the thank you page and mimic an
example that we like as iteration 1:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "749px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "59.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB7CavExUD/8QAGxAAAQQDAAAAAAAAAAAAAAAAAgABAxARExT/2gAIAQEAAQUC1sihAn54lm//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BZH//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGBAAAgMAAAAAAAAAAAAAAAAAAAEgITL/2gAIAQEABj8CLRmH/8QAHBABAAIBBQAAAAAAAAAAAAAAAQARECFBUaHw/9oACAEBAAE/Ibdu5aGdOWeKwdpDH//aAAwDAQACAAMAAAAQ9A//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFh/9oACAEDAQE/EFGZH//EABgRAAIDAAAAAAAAAAAAAAAAAAABESFR/9oACAECAQE/EHRLT//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQWEQscH/2gAIAQEAAT8Qz8y+RD7l/Bg9WuYPdh7W+zTlESlvj//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Thank You Page",
          "title": "Thank You Page",
          "src": "/static/e6149d477f2d2304d3f67200c9e8b04a/f614f/thank-you-page.jpg",
          "srcSet": ["/static/e6149d477f2d2304d3f67200c9e8b04a/a6407/thank-you-page.jpg 400w", "/static/e6149d477f2d2304d3f67200c9e8b04a/f614f/thank-you-page.jpg 749w"],
          "sizes": "(max-width: 749px) 100vw, 749px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`This stuff might sound minor, but that’s the name of the game.`}</p>
    <p><strong parentName="p">{`We don’t chase silver bullets`}</strong>{` - wishing that one change will fix everything.
Instead we `}<strong parentName="p">{`fire lots of lead bullets`}</strong>{` - small wins that add up and compound
over time.`}</p>
    <p>{`Success is a lot less sexy than most people think. It’s not genius master
strategies. It’s simple stuff, done well. It’s a lot of tweaking and testing,
and celebrating each step.`}</p>
    <p><strong parentName="p">{`Learning #3 - Call with our buddy
`}<a parentName="strong" {...{
          "href": "https://click.convertkit-mail2.com/75ulv2g0gwa8hq8390fz/08hwh9hm0kd8d5ul/aHR0cHM6Ly90d2l0dGVyLmNvbS9MZWxhbmREZWVocmluZw=="
        }}>{`Leland`}</a>{`.`}</strong></p>
    <p>{`If you can’t tell, I love to do “office hours” with friends. These are two-way
relationships. I help them with stuff, they help me with stuff. Nobody keeps
score.`}</p>
    <p>{`So I asked my buddy Leland, a fellow AAP member, to chat today and get his
advice. He had some gems in the conversation.`}</p>
    <p><strong parentName="p">{`My key takeaways from the chat were:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Don’t let designers design the store`}</strong>{`. Pretty stores tend not to convert as
well. Pick `}<strong parentName="li">{`Function over Fashion.`}</strong>{` He showed us some examples of simple
things that are uglier, but convert way better than the pretty version.`}</li>
      <li parentName="ul"><strong parentName="li">{`Trust is the name of the game`}</strong>{`. Your conversion rate is based on how much
customers trust you and your product. So he has little tiny “trust hooks”
everywhere in his store. Like when websites say “as seen on ABC!” or “secure
checkout” etc… He pointed out their phone number in the top of the site. Most
people never call, but having a phone number increases trust (I’ve heard this
exact same thing from other entrepreneurs. One added the ugly gold “lock” icon
at checkout and saw a 5% lift).`}</li>
      <li parentName="ul"><strong parentName="li">{`Our facebook ad strategy is too complicated`}</strong>{` - His take is that FB has
gotten really great at figuring out who to target. You’re better off just
letting them do their thing. He recommended creating a few high level
campaigns like “New Customers”, “Engagement” and “Retargetting” and then just
focusing on finding winning ad creative and putting all your budget in
facebook’s hands to do their magic.`}</li>
    </ul>
    <p>{`OK back to the lab. Tomorrow we’ll be back with more growth stuff. Both on the
ecom side and building our audience.`}</p>
    <p>{`-`}{` shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docs.google.com/spreadsheets/d/1STmgyeA9WzDca03L4ob07Bga5w8GnSvqyLiD0lPFahA/edit#gid=0',
        label: 'AAP Growth Templates Google Sheet'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://twitter.com/LelandDeehring',
        label: 'Leland from Bump Boxes'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.the_art_of_non_violent_communication.label,
      slug: slugify(postMap.the_art_of_non_violent_communication.slug)
    }} nextButton={{
      text: postMap.finding_the_bug_in_the_system.label,
      slug: slugify(postMap.finding_the_bug_in_the_system.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      